import {useEffect, useState} from "react";
import { useParams,useNavigate } from "react-router-dom";
import ReactQuill from 'react-quill';
import { ImageActions } from '@xeger/quill-image-actions';
import { ImageFormats } from '@xeger/quill-image-formats';
//import QuillResizeImage from 'quill-resize-image';
import 'react-quill/dist/quill.snow.css';
import {Container,Row,Col,Form,Button} from 'react-bootstrap';
import PocketBase from 'pocketbase';

//import './tstory.css';

import Import from './import'
import Export from './export'
import TableList from './tablelist'
import Info from '../info'
const info = Info()

const pb = new PocketBase(info.api);
const keys=info.keys['notice']

const { Quill } = ReactQuill;
Quill.register('modules/imageActions', ImageActions)
Quill.register('modules/imageFormats', ImageFormats);
//Quill.register("modules/resize", QuillResizeImage);
const formats = ['align', 'background', 'blockquote', 'bold', 'code-block', 'color', 'float', 'font', 'header', 'height', 'image', 'italic', 'link', 'script', 'strike', 'size', 'underline', 'width']
const modules = {
    imageActions: {},
    imageFormats: {},
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction

      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],

      ['clean']

    ]
  }

function NoticeView(){
    const {id} = useParams()
    let navigate = useNavigate();

    const [togle, setTogle] = useState(false)
    const [items, setItems] = useState([[]]);
    const [isEdit, setIsEdit] = useState(false);
    const [body, setBody] = useState('');

    const onTogle = (e)=>{

        console.log(e.target.checked);
        setTogle(e.target.checked);
    }
    const onSave = async ()=>{

        console.log(body);
        if(items[0].body !== body)
        {
            items[0].body = body;
            console.log(items[0].body)
            const record = await pb.collection('notice').update(items[0].id,items[0]);
            console.log(record);
        }
        setIsEdit(false);
    }
    const onCancel = async ()=>{

        setBody(items.length===0 ? '' : items[0].body);
        setIsEdit(false)
    }
    useEffect(()=>{    

        pb.collection('notice').getList(1, 1000, {
            filter: id === undefined ? 'created >= "2022-01-01 00:00:00"' : `created >= "2022-01-01 00:00:00" && index=${id}`,
        }).then((res)=>{

            console.log( res.items );

            
            setItems(res.items);
            setBody(res.items.length===0 ? '' : res.items[0].body);

            
        }, (err)=>{

            console.log('---------- err : '+ err );
        });

    },[id]);

    if(id!==undefined ){
        return (
            <main className="main" >
                <Container>
                    <Row>
                        <Col md={1}><Button variant="link" onClick={()=>navigate(-1)}>{'Back'}</Button></Col>
                        {
                            isEdit
                            ?(<>
                            <Col md={1}><Button onClick={onSave}>{'SAVE'}</Button></Col>                            
                            <Col><Button onClick={onCancel}>{'CANCEL'}</Button></Col>
                            </>)
                            :(<Col md={1}><Button onClick={()=>setIsEdit(true)}>{'Edit'}</Button></Col>)
                        }
                    </Row>
                </Container>
                <Container>
                     {
                                items.length===0 ? <div>'undefined'</div>:(
                                 isEdit ? 
                                 <ReactQuill 
                                    formats={formats}
                                    modules={modules}
                                    theme="snow" value={body} onChange={setBody} />
                                 :(<div dangerouslySetInnerHTML={ {__html: body} }></div>)    
                                )
                            }
                </Container>
            </main>
        )
    }

    return(
        <main className="main" >
            <Container>
                <Row>
                <Col md={1}><Export keys={keys} items={items} hides={['body']}/></Col>
                <Col md={6}><Import items={items} update={setItems} totle={togle} tableName={'notice'} /></Col>            
                </Row>
                <Row>
                <Col>
                    <Form.Check type="checkbox" label="Import 시 기존 테이블 초기화" onChange={onTogle} />
                </Col>            
                </Row>
            </Container>
            <TableList keys={keys} tableName={'notice'} items={items} update={setItems} edits={
                { body:(item)=>{

                    navigate(`/notice/${item.index}`)
                }}}/> 
            
      </main>
    );
}

export default NoticeView;